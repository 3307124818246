// 护理员详情

<template>
  <div class="nurseDetail clearfix ">
    <div class="nurseDetail_page">
      <div class="nurseDetail_title base_333_color ">
        <img src="../image/shouye(1).png" alt="" />
        <span class="cursor" @click="go('home')">首页 ></span>
        <span class="cursor" @click="go('nurseList')"> 护理员档案 > </span>
        <span class="cursor"> {{ dataList.name }}详情页</span>
      </div>
      <!---------------- 护理员详情-------------------->

      <div class="nurseDetail_con">
        <!-- ------------------详情左-------------- -->
        <div class="nurseDetail_con_left">
          <div class="nurse_photo ">
            <a-avatar
              @click="showModal"
              class="cursor"
              shape="square"
              :size="120"
              icon="user"
              :src="dataList.avatar"
            />
            <a-modal
              :visible="visible"
              @ok="handleOk"
              :title="dataList.name"
              @cancel="handleOks"
              :footer="[]"
            >
              <div class="img_big">
                <img :src="dataList.avatar" alt="" />
              </div>
            </a-modal>
          </div>
          <div class="small_title base_333_color ">
            <img src="../image/gerenxinxi.png" alt="" />

            <span>护理员个人资料</span>
          </div>
          <div class="small_con base_666_color">
            <div class="person">
              <span class="person_left"
                >姓名:&nbsp;&nbsp;{{ dataList.name }}&nbsp;&nbsp;
                <img src="../image/real-name.png" alt=""
              /></span>
              <span class="person_right"
                >年龄:&nbsp;&nbsp;{{ dataList.age }}岁</span
              >
            </div>
            <div class="person">
              <span class="person_left"
                >身高:&nbsp;&nbsp;{{
                  dataList.stature == null
                    ? "暂未填写"
                    : `${dataList.stature}cm`
                }}</span
              >
              <span class="person_right"
                >性别:&nbsp;&nbsp;{{ dataList.sex_dictText }}</span
              >
            </div>
            <div class="person">
              <span class="person_left"
                >工作经验:&nbsp;&nbsp;{{
                  dataList.workYears == "null"
                    ? "暂未填写"
                    : `${dataList.workYears}年`
                }}</span
              >
              <span class="person_right"
                >体重:&nbsp;&nbsp;{{
                  dataList.weight == null ? "暂未填写" : `${dataList.weight}kg`
                }}</span
              >
            </div>
            <div class="person">
              <span class="person_left"
                >籍贯:&nbsp;&nbsp;{{
                  dataList.censusRegisterGroundFullAddress == null
                    ? "暂未填写"
                    : dataList.censusRegisterGroundFullAddress
                }}</span
              >
            </div>
          </div>
          <div class="small_title base_333_color ">
            <img src="../image/zizhizhengshu.png" alt="" />
            <span>相关资质</span>
          </div>
          <div class="small_con base_666_color">
            <div class="person papers" v-if="qualification.length == 0">
              <span>暂未填写</span>
            </div>
            <div class="person papers" v-else>
              <span v-for="(items, indexs) of qualification" :key="indexs">{{
                `《${items}》`
              }}</span>
            </div>
          </div>
          <div class="small_title base_333_color ">
            <img src="../image/hulicuoshizhihang.png" alt="" />
            <span>擅长服务</span>
          </div>
          <div class="small_con base_666_color">
            <div class="person skill base_color2" v-if="dataList.tags == null">
              <span>暂未填写</span>
            </div>
            <div class="person skill base_color2" v-else>
              <span v-for="(item, index) of dataList.tags" :key="index">{{
                item
              }}</span>
            </div>
          </div>
        </div>
        <!-- ------------------详情右-------------- -->

        <div class="nurseDetail_con_right">
          <div class="small_title base_333_color ">
            <img src="../image/ziwojieshao.png" alt="" />
            <span>自我介绍</span>
          </div>
          <div class="small_con base_666_color">
            <div class="person ">
              <div class="dotted">
                {{
                  dataList.selfIntroduction == null
                    ? "暂未填写"
                    : dataList.selfIntroduction
                }}
              </div>
            </div>
          </div>
          <div class="small_title base_333_color ">
            <img src="../image/tubiaozhizuomoban-.png" alt="" />
            <span>工作经验</span>
          </div>
          <div class="small_con base_666_color">
            <div class="person ">
              <div
                class="job"
                v-if="
                  dataList.workExperienceList.length == 0
                    ? true
                    : dataList.workExperienceList[0].workContent == null
                    ? true
                    : false
                "
              >
                <span class="job_time">暂未填写</span>
              </div>

              <div class="job" v-else>
                <block
                  v-for="(item, index) of dataList.workExperienceList"
                  :key="index"
                >
                  <span class="job_time"
                    >{{ item.workStartDate }}~{{ item.workEndDate }}</span
                  >
                  <span class="job_con">
                    {{ item.workContent }}
                  </span>
                </block>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!---------------- 右侧导航-------------------->
    <ebutton class="right_btn ">
      <template #one>
        <button @click="goContract" class="btn1 cursor base_white_color">
          创建服务合同
        </button>
      </template>
      <template #two>
        <button @click="createUser" class="btn2 cursor base_white_color">
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import ebutton from "../components/RightList"; // 右侧导航 插槽
import { getNurseDetailID } from "../api/api";
export default {
  data() {
    return {
      dataList: null,
      qualification: [],
      visible: false,
    };
  },

  components: { ebutton },
  async created() {
    let res = await getNurseDetailID({
      id: this.$router.currentRoute._rawValue.query.id,
    });
    this.dataList = res.data.result.records[0];
    if (this.dataList.qualificationCertificate !== null) {
      this.qualification = this.dataList.qualificationCertificate.split(",");
    }
  },

  methods: {
    go(key) {
      switch (key) {
        case "home":
          this.$router.push("/home");
          break;
        case "nurseList":
          this.$router.push("./nurseList");
          break;
        default:
          break;
      }
    },
    goContract() {
      this.$router.push("/serveManage");
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    handleOks() {
      this.visible = false;
    },
  },
};
</script>

<style scoped>
.nurseDetail {
  background: #fafafd;
  position: relative;
}
.nurseDetail_page {
  width: 1782px;
  text-align: left;
  margin-left: 50px;
  position: relative;
  padding-bottom: 80px;
}
.nurseDetail_title {
  padding: 28px 0;
  font-size: 22px;
}
.nurseDetail_title img {
  width: 22px;
  height: 22px;
  margin-right: 14px;
  vertical-align: text-top;
}
.nurseDetail_con {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.27);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  padding: 26px 196px 48px 58px;
}
/* --------------------------详情左------------------------------ */

.nurseDetail_con_left {
  width: 687px;
}

.nurse_photo {
  text-align: center;
}

:deep(.ant-avatar) {
  border-radius: 50%;
}
.small_title {
  padding: 26px 0;
  font-size: 22px;
  border-bottom: 2px solid #e2e2e2;
}
.small_title > img {
  margin-top: -4px;
  width: 24px;
  height: 24px;
}
.small_title span {
  margin-left: 16px;
}
.small_con {
  margin-left: 42px;
}
.person {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 20px;
  margin-top: 22px;
}
.person_left {
  width: 417px;
}
.person_left img {
  width: 66px;
  height: 24px;
  vertical-align: text-top;
}
.papers span {
  margin-right: 2px;
  margin-bottom: 14px;
}
.skill {
  margin-top: 2px;
}
.skill span {
  background: rgba(255, 184, 128, 0.1);
  border-radius: 18px;
  border: 1px solid #ffb880;
  height: 36px;
  line-height: 36px;
  margin-right: 14px;
  padding: 0 14px;
  margin-top: 28px;
}

/* --------------------------详情右------------------------------ */
.nurseDetail_con_right {
  width: 753px;
}
.dotted {
  background: rgba(244, 254, 248, 0.26);
  border-radius: 12px;
  border: 1px dashed #7fd69f;
  padding: 23px 29px;
  line-height: 38px;
  width: 100%;
}

.job {
  line-height: 46px;
  margin-top: -4px;
}
.job_time {
  padding-top: 10px;
}
.job_time,
.job_con {
  display: block;
}
/* ===================================右侧导航样式======================================== */

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  /* height: 100vh; */
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
.img_big {
  text-align: center;
}
.img_big img {
  width: 100%;
  height: auto;
}
</style>
